import api from "../../../api/api.js";

export default {
  async fetchCategories(context) {
    try {
      let response = await api.get();

      let questions = [];
      for (let [key, value] of Object.entries(response.data.questions)) {
        value.id = key;
        questions.push(value);
      }
      context.commit('setQuestions', questions);

      let products = [];
      for (let [key, value] of Object.entries(response.data.sku)) {
        value.id = key;
        products.push(value);
      }
      context.commit('setProducts', products);
    } catch (err) {
      alert(err);
    }
  }
}