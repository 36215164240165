<template>
  <li
    class="list-group-item d-flex flex-row align-items-center"
    @click="$emit('go-forward')"
  >
    <div>
      <img v-if="imageUrl" class="img-thumbnail" :src="imageUrl" />
    </div>
    <div class="text-start px-2">
      {{ category }}
    </div>
    <button v-if="hintText" class="btn ms-auto" @click.stop="changeVisual">
      <icon-up v-if="showHint"></icon-up>
      <icon-down v-else></icon-down>
    </button>
  </li>
  <div class="collapse" :id="collapseTextId">
    <div class="card card-body text-start" v-html="hintText"></div>
  </div>
</template>

<script>
import IconDown from "../icons/IconDown.vue";
import IconUp from "../icons/IconUp.vue";

export default {
  components: {
    IconDown,
    IconUp,
  },
  props: ["category", "hintText", "imageUrl", "id"],
  emits: ["go-forward"],
  data() {
    return {
      showHint: false,
    };
  },
  computed: {
    collapseTextId() {
      return "collapseText" + this.id;
    },
  },
  methods: {
    changeVisual() {
      this.showHint = !this.showHint;
      let openedItem = document.querySelector("#" + this.collapseTextId);
      openedItem.classList.toggle("show");
    },
  },
};
</script>

<style scoped>
li {
  min-height: 56px;
}
div > img {
  height: 100px;
  width: 100px;
}
img {
  height: 100px;
  max-width: 100px;
  object-fit: contain;
}
.collapse {
  font-size: 14px;
}
</style>
