<template>
  <div>
    <p class="fs-2">{{ title }}</p>
    <ul class="list-group">
      <category-item
        v-for="(category, index) in categories"
        :key="category.title"
        :category="category.title"
        :hint-text="category.text"
        :image-url="category.image"
        :id="index"
        @go-forward="goForward(category.title)"
      ></category-item>
    </ul>
    <base-nav>
      <base-button v-if="currentStep != 1" @click="$emit('go-back')">Назад</base-button>
    </base-nav>
  </div>
</template>

<script>
import CategoryItem from "./CategoryItem.vue";

export default {
  components: {
    CategoryItem,
  },
  props: ["categories"],
  emits: ["go-back"],
  computed: {
    title() {
      return this.$store.getters["steps/currentTitle"];
    },
    currentStep() {
      return this.$store.getters["steps/currentStep"];
    },
  },
  methods: {
    goForward(clickedCategory) {
      let choosedCategory = this.categories.find(
        (category) => category.title === clickedCategory
      );
      this.$store.dispatch("steps/saveStep", {
        step: this.currentStep,
        choosedCategory: clickedCategory,
        stepCategories: this.categories,
      });

      if (!choosedCategory.children) {
        this.$store.dispatch("steps/updateStepView", { stepView: "submitForm" });
      } else {
        this.$store.dispatch("steps/updateCategories", {
          choosedCategory: choosedCategory,
        });
      }

      this.$store.dispatch("steps/goForward");
    },
  },
};
</script>
