export default {
  currentStep(state) {
    return state.currentStep;
  },
  currentTitle(state) {
    return state.currentTitle;
  },
  currentCategories(state) {
    return state.currentCategories;
  },
  currentStepView(state) {
    return state.currentStepView;
  },
  data(state) {
    return state.data;
  },
  lastStep(state) {
    return state.steps.pop();
  },
  allSteps(state) {
    return state.steps;
  }
}