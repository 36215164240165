export default {
  setStep(state, payload) {
    state.currentStep = state.currentStep + payload;
  },
  setTitle(state, payload) {
    state.currentTitle = payload;
  },
  setCategories(state, payload) {
    state.currentCategories = payload;
  },
  setStepView(state, payload) {
    state.currentStepView = payload;
  },
  setProducts(state, payload) {
    state.currentProducts = payload;
  },
  addStep(state, payload) {
    state.steps.push(payload);
  },
  updateData(state, payload) {
    state.data.push(payload);
  },
  deleteStepFromData(state) {
    state.data.splice(-1,1);
  } 
}