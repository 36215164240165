import { createStore } from 'vuex'

import categoriesModule from './modules/categories/index.js';
import stepsModule from './modules/steps/index.js';
import autnModule from './modules/auth/index.js';

const store = createStore({
  modules: {
    categories: categoriesModule,
    steps: stepsModule,
    auth: autnModule
  }
});

export default store;