export default {
  goForward(context) {
    context.commit('setStep', 1);
  },
  goBack(context) {
    let lastStep = context.getters.lastStep;
    context.commit('setCategories', lastStep.stepCategories);
    context.commit('setStep', -1);
    context.commit('deleteStepFromData');
  },
  updateCategories(context, payload) {
    if (payload.choosedCategory.children.length > 0) {
      context.commit('setCategories', payload.choosedCategory.children)
    } else {
      let products = context.rootState.categories.sku;
      let filteredProducts = products.filter(product => payload.choosedCategory.sku.includes(product.id));

      context.commit('setCategories', filteredProducts);
      context.dispatch('updateStepView', { stepView: "sku" });
    }
  },
  saveStep(context, payload) {
    context.commit('addStep', {
      step: payload.step,
      choosedCategory: payload.choosedCategory,
      stepCategories: payload.stepCategories
    });

    let stepForData = payload.stepCategories.findIndex(category => category.title === payload.choosedCategory) + 1;
    context.commit('updateData', context.getters.data.length === 1 ? stepForData.toString() : context.getters.data[context.getters.data.length - 1] + "." + stepForData);
  },
  updateStepView(context, payload) {
    context.commit('setStepView', payload.stepView);
  }
}