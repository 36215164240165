<template>
  <!-- <div v-if="!isAuth">
    <auth-form></auth-form>
  </div> -->
  <div v-if="isLoading" class="spinner-border text-primary" role="status">
    <span class="visually-hidden">Загрузка...</span>
  </div>
  <div v-else>
    <div v-if="isCategoryStep" class="step">
      <category-step :categories="categories" @go-back="goBack"></category-step>
    </div>
    <div v-else class="step">
      <form-step @go-back="goBack"></form-step>
    </div>
  </div>
</template>

<script>
import CategoryStep from "../components/categories/CategoryStep.vue";
import FormStep from "../components/forms/FormStep";
// import AuthForm from "../components/forms/AuthForm.vue";
import cookieMixins from "../mixins/cookie.js";

export default {
  components: {
    CategoryStep,
    FormStep,
    // AuthForm,
  },
  computed: {
    currentStep() {
      return this.$store.getters["steps/currentStep"];
    },
    isCategoryStep() {
      return (
        this.$store.getters["steps/currentStepView"] === "category" ||
        this.$store.getters["steps/currentStepView"] === "sku"
      );
    },
    categories() {
      return this.currentStep === 1
        ? this.$store.getters["categories/questions"]
        : this.$store.getters["steps/currentCategories"];
    },
    isLoading() {
      return this.$store.getters["categories/isLoading"];
    },
    currentStepView() {
      return this.$store.getters["steps/currentStepView"];
    },
    // isAuth() {
    //   return this.$store.getters["isUserAuth"];
    // },
  },
  mixins: [cookieMixins],
  watch: {
    currentStepView(newCurrentStepView) {
      if (newCurrentStepView === "submitForm") {
        let isCookieExist = this.getCookie("openFinalForm");
        !isCookieExist && this.setCookie("openFinalForm", "yes", 90);
      } else if (newCurrentStepView === "sku") {
        let isCookieExist = this.getCookie("openSkuList");
        !isCookieExist && this.setCookie("openSkuList", "yes", 90);
      }
    },
  },
  methods: {
    goBack() {
      this.$store.dispatch("steps/goBack");
    },
    async fetchCategories() {
      await this.$store.dispatch("categories/fetchCategories");
    },
  },
  mounted() {
    this.fetchCategories();

    let isCookieExist = this.getCookie("openMainScreen");
    !isCookieExist && this.setCookie("openMainScreen", "yes", 90);
  },
};
</script>

<style scoped>
.step {
  max-width: 500px;
  width: 75vw;
}
</style>
