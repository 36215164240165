export default {
  questions(state) {
    return state.questions;
  },
  products(state) {
    return state.sku;
  },
  isLoading(state) {
    return state.questions.length === 0;
  }
}