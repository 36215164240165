<template>
  <p class="fs-2">Пожалуйста, заполните форму</p>
  <p class="text-start">Выбранный товар: {{ choosedProduct }}</p>
  <p class="text-start">Тема обращения: {{ choosedCategories }}</p>
  <form class="text-start" @submit.prevent="submitData">
    <div class="mb-3">
      <label for="lastName" class="form-label">Фамилия:</label>
      <input
        type="text"
        class="form-control"
        :class="{ 'is-invalid': isLastNameInvalid }"
        id="lastName"
        v-model.trim="lastName"
      />
    </div>
    <div class="mb-3">
      <label for="firstName" class="form-label">Имя:</label>
      <input
        type="text"
        class="form-control"
        :class="{ 'is-invalid': isFirstNameInvalid }"
        id="firstName"
        v-model.trim="firstName"
      />
    </div>
    <div class="mb-3">
      <label for="patronymic" class="form-label">Отчество:</label>
      <input type="text" class="form-control" id="patronymic" v-model.trim="patronymic" />
    </div>
    <div class="mb-3">
      <label for="phone" class="form-label">Телефон:</label>
      <input
        type="tel"
        class="form-control"
        :class="{ 'is-invalid': isPhoneInvalid }"
        id="phone"
        v-model.trim="phone"
      />
    </div>
    <div class="mb-3">
      <label for="email" class="form-label">E-mail:</label>
      <input type="email" class="form-control" id="email" v-model.trim="email" />
    </div>
    <div class="mb-3">
      <label for="address" class="form-label">Адрес:</label>
      <textarea class="form-control" id="address" rows="3" v-model="address"></textarea>
    </div>
    <div class="mb-3">
      <label for="files" class="form-label">
        Приложить фото/видео (при необходимости):
      </label>
      <input
        type="file"
        multiple
        class="form-control"
        id="files"
        ref="files"
        @change="handleFileChange"
      />
    </div>
    <base-nav>
      <base-button @click="goBack">Назад</base-button>
      <base-button type="submit">Отправить</base-button>
    </base-nav>
  </form>
</template>

<script>
export default {
  emits: ["go-back"],
  data() {
    return {
      lastName: "",
      firstName: "",
      patronymic: "",
      phone: "",
      email: "",
      address: "",
      files: [],
      isLastNameInvalid: false,
      isFirstNameInvalid: false,
      isPhoneInvalid: false,
    };
  },
  computed: {
    steps() {
      return this.$store.getters["steps/allSteps"];
    },
    choosedProduct() {
      return this.steps[this.steps.length - 1].choosedCategory;
    },
    choosedCategories() {
      let categories = "";
      for (let i = 0; i < this.steps.length - 1; i++) {
        if (i === 0) {
          categories = this.steps[i].choosedCategory;
        } else {
          categories = categories + " / " + this.steps[i].choosedCategory;
        }
      }
      return categories;
    },
  },
  methods: {
    handleFileChange() {
      this.files = this.$refs.files.files;
    },
    resetForm() {
      this.lastName = "";
      this.firstName = "";
      this.patronymic = "";
      this.phone = "";
      this.email = "";
      this.address = "";
      this.files = [];
    },
    submitData() {
      this.isLastNameInvalid = !this.lastName ? true : false;
      this.isFirstNameInvalid = !this.firstName ? true : false;
      this.isPhoneInvalid = !this.phone ? true : false;

      if (!this.isLastNameInvalid && !this.isFirstNameInvalid && !this.isPhoneInvalid) {
        // submit form
        this.resetForm();
      }
    },
    goBack() {
      this.$emit("go-back");
      this.$store.dispatch("steps/updateStepView", {
        stepView: "category",
      });
    },
  },
};
</script>
