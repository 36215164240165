import { createApp } from 'vue'
import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap/dist/js/bootstrap.min.js"
import "bootstrap"

import store from './store/index.js'
import App from './App.vue'
import BaseButton from './components/ui/BaseButton.vue'
import BaseModal from './components/ui/BaseModal.vue'
import BaseNav from './components/ui/BaseNav.vue'

const app = createApp(App)

app.use(store)

app.component('base-button', BaseButton)
app.component('base-modal', BaseModal)
app.component('base-nav', BaseNav)

app.mount('#app')
