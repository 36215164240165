import getters from "./getters"
import actions from "./actions"
import mutations from "./mutations"

export default {
  namespaced: true,
  state() {
    return {
      currentStep: 1,
      currentTitle: "Ваше обращение:",
      currentCategories: [],
      currentStepView: "category",
      steps: [],
      data: ["0"],
    }
  },
  getters,
  actions,
  mutations
}